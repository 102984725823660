<template>
  <div
    class="lock-cover"
    :style="
      'background-image: url(' +
      require('../../../assets/woman-doing-accounting.jpg') +
      ');'
    "
  >
    <div class="wrapper wrapper-full-page"></div>
    <div class="full-page lock-page" data-color="primary" data-image="wda">
      <div class="card card-lock">
        <div class="card-body">
          <div class="user z-depth-1">
            <img
              :src="
                $store.state.auth.user.avatar
                  ? $store.state.url + 'storage/avatar/' + $store.state.auth.user.avatar
                  : $store.state.url + 'img/profile.png'
              "
              alt=""
            />
          </div>
          <div class="h4">
            {{ $store.state.auth.user.firstname }}
            <span class="text-uppercase">{{ $store.state.auth.user.lastname }}</span>
          </div>
          <form @submit.prevent="checkPwd">
            <div class="form-group">
              <mdb-input
                aria-label="Mot de passe"
                label="Mot de passe"
                outline
                v-model="credential.password"
                ref="pref"
                :validation="$store.state.user.errors.password ? true : false"
                :invalidFeedback="$store.state.user.errors.password"
                type="password"
              ></mdb-input>
              <mdb-btn
                type="submit"
                :disabled="pwdBtn"
                color="primary"
                size="md"
              >
                <span v-if="!pwdBtn"> Déverouiller</span>
                <span
                  v-if="pwdBtn"
                  class="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                ></span>
                <span v-if="pwdBtn" class="pl-2">Loading...</span>
              </mdb-btn>
              <br />
              <a @click="logout" class="chan">Changer de compte</a>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mdbInput, mdbBtn } from "mdbvue";
import Lock from "@/services/system-lock/Lock";
export default {
  name: "lock-page",
  metaInfo: {
    title: "TECBILL APP",
    titleTemplate: "%s - VERROUILLE",
    htmlAttrs: {
      lang: "fr",
      amp: true,
    },
  },
  components: {
    mdbInput,
    mdbBtn,
  },
  data() {
    return {
      pwdBtn: false,
      credential: {
        password: "",
      },
    };
  },
  methods: {
    async checkPwd() {
      this.$nprogress.start();
      this.$store.commit("user/SET_PWD", "");
      this.pwdBtn = !this.pwdBtn;
      await this.$store
        .dispatch("user/unLockMe", this.credential)
        .then(() => {
          this.$nprogress.done();
          this.pwdBtn = !this.pwdBtn;
          this.credential.password = "";
          Lock.unlock();
          // this.$router.push("/");
          this.$router.go(-2);
          this.$notify({
            message: "Compte déverrouillé",
            type: "success",
          });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.pwdBtn = !this.pwdBtn;
          if (error.response.data.errors) {
            this.$store.commit("user/SET_PWD", error.response.data.errors.password);
          }
        });
    },
    async logout() {
      this.$nprogress.start();
      this.$store
        .dispatch("auth/logout")
        .then(() => {
          this.$nprogress.done();
          this.$router.push({ path: "/login" });
        })
        .catch((error) => {
          this.$nprogress.done();
          this.$notify({
            message: error.response.data.message || error.response.data.error,
            type: "danger",
          });
        });
    },
  },
  created() {
    this.$nextTick(function () {
      this.$refs.pref.focus();
    });
  },
};
</script>
<style lang="scss" scoped>
.chan {
  display: inline-block;
  margin-top: 10px;
  font-size: 15px;

  &:hover {
    text-decoration: underline !important;
  }
}
</style>
<style lang="scss">
@import url('https://fonts.googleapis.com/css2?family=Ubuntu:ital,wght@0,300;0,400;0,500;0,700;0,800;0,900;1,300;1,400;1,500;1,700;1,800;1,900&display=swap');
.lock-cover {
  *{
    font-family: 'Ubuntu', sans-serif !important;
  }
  .h4{
    font-weight: 500 !important;
  }
}
</style>